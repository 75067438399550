import { Header } from "./components/header";
import { About } from "./components/about";
import { Sponsors } from "./components/sponsors";
import { Artists } from "./components/artists";
import { Events } from "./components/events";
import { Schedule } from "./components/schedule";
import { Contact } from "./components/contact";
import {Impressum} from "./components/impressum"
import JsonData from "./data/data.json";
import JsonData22 from "./data/data22.json";
import JsonData23 from "./data/data23.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: false,
});

const App = () => {
  // const [landingPageData, setLandingPageData] = useState({});
  // useEffect(() => {
  //   // setLandingPageData(JsonData);
  // }, []);

  return (
    <div>
      {/* <Navigation /> */}

      <Header data={JsonData23.Header} />
      <Events data={JsonData23.Events}/>
      {/* <Artists data={JsonData23.Artists} /> */}

      <About data={JsonData.About} />

      <Events data={JsonData22.Events}/>
      <Artists data={JsonData22.Artists} />

      <Schedule data={JsonData.Schedule} />

      <Sponsors data={JsonData.Sponsors} />
      <Contact data={JsonData.Contact} />
      <Impressum/>

    </div>
  );
};

export default App;